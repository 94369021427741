export const player_layout_positions = ["G", "LD", "RD", "LW", "C", "RW"];

export const FREE_AGENCY_CLASS_COLORS = {
    UFA_G3: "var(--tw-green-100)",
    UFA_G6: "var(--tw-green-100)",
    RFA_G2: "var(--ch-blue-100)",
    RFA_G2_ARB: "var(--ch-blue-100)",
    "10_2C": "var(--tw-yellow-100)",
};

export const FREE_AGENCY_CLASS_LABELS = {
    UFA_G3: "UFA",
    UFA_G6: "UFA G6",
    RFA_G2: "RFA",
    RFA_G2_ARB: "RFA Arb",
    "10_2C": "10.2c",
};

export const PLAYER_PROJECTION_BG_COLORS = {
    F1: "var(--tw-yellow-325)",
    F2: "var(--tw-yellow-400)",
    F3: "var(--tw-yellow-500)",
    F4: "var(--tw-green-325)",
    F5: "var(--tw-green-400)",
    F6: "var(--tw-green-500)",
    F7: "var(--ch-blue-250)",
    F8: "var(--ch-blue-325)",
    F9: "var(--ch-blue-400)",
    F10: "var(--tw-slate-300)",
    F11: "var(--tw-slate-350)",
    F12: "var(--tw-slate-400)",
    F13: "var(--ch-red-250)",
    F14: "var(--ch-red-325)",
    F15: "var(--ch-red-400)",
    D1: "var(--tw-yellow-400)",
    D2: "var(--tw-yellow-500)",
    D3: "var(--tw-green-400)",
    D4: "var(--tw-green-500)",
    D5: "var(--ch-blue-325)",
    D6: "var(--ch-blue-400)",
    D7: "var(--tw-slate-350)",
    D8: "var(--tw-slate-400)",
    D9: "var(--ch-red-325)",
    D10: "var(--ch-red-400)",
    G1: "var(--tw-yellow-500)",
    G2: "var(--tw-green-500)",
    G3: "var(--ch-blue-400)",
    G4: "var(--tw-slate-400)",
    G5: "var(--ch-red-400)",
};

export const earliestFullSeasonYearCompetitionMap = {
    1: 2017, // NHL
    2: 2018, // AHL
    3: 2019, // QMJHL
    4: 2019, // OHL, note that the 2020-21 season was cancelled due to COVID
    5: 2019, // WHL
    9: 2017, // SHL
    10: 2021, // HockeyAllsvenskan
    13: 2023, // NCAA
    14: 2019, // USHL
};

export const MIN_ES_5V5_THRESHOLD = {
    G: 250.0 * 60.0,
    F: 75.0 * 60.0,
    D: 75.0 * 60.0,
};

export const CURRENT_DRAFT = {
    year: 2024,
    logo_url: "/static/static/dashboards/2024_NHL_entry_draft_logo.png",
    img_position: "50% 61%",
};

export const PlayerIndexMetrics = {
    "1 Timer Shots p20oz": {
        name: "1 Timer Shots p20oz",
        label: "1 Timer Shots p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        decimalPlaces: 2,
        autoScaleMax: true,
    },
    "Avg Gap Distance": {
        name: "Avg Gap Distance",
        label: "Avg Gap Distance",
        positionScope: ["D"],
        maxValue: null,
        independentMaxValue: true,
        decimalPlaces: 1,
        autoScaleMax: true,
    },
    SOOD: {
        name: "SOOD",
        label: "Matchup",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        decimalPlaces: 2,
        autoScale: true,
    },

    "SOT Percentage": {
        name: "SOT Percentage",
        label: "SOT%",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
    },

    "OZ Starts Percentage": {
        name: "OZ Starts Percentage",
        label: "OZ Starts %",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
    },

    "All MP TOI Seconds per Game": {
        name: "All MP TOI Seconds per Game",
        label: "TOI minutes/game",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        isSeconds: true,
        hideTeamAverage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
        autoScale: true,
    },

    "Player Index": {
        name: "Player Index",
        label: "Impact Value",
        positionScope: ["D", "F", "G"],
        maxValue: 1.0,
        decimalPlaces: 2,
        autoScale: false,
        componentMetrics: [
            "Defense and Puck Battles",
            "Touch Efficiency",
            "Chance Generation",
            "Scoring",
            "Goaltending",
            "Puck Handling",
            "Quality Starts Percentage",
            "No Rebound Rate",
        ],
    },

    // Player Index Breakdown Metrics
    "Defense and Puck Battles": {
        name: "Defense and Puck Battles",
        label: "Defensive Kills & Puck Battles",
        positionScope: ["D", "F"],
        maxValue: 1.0,
        decimalPlaces: 2,
        autoScale: false,
        componentMetrics: [
            "Defensive Touches Rating",
            "LPRs Rating",
            "Distance Closed Percentage",
            "OGPs Ag WOI p20",
        ],
    },
    "Touch Efficiency": {
        name: "Touch Efficiency",
        label: "Touch Efficiency",
        positionScope: ["D", "F"],
        maxValue: 1.0,
        decimalPlaces: 2,
        autoScale: false,
        componentMetrics: [
            "Adjusted PDPs p20dznz",
            "OZ Offensive Touches p20oz",
            "True Turnover Rate",
            "Total Team Pressured Possession Share",
        ],
    },
    "Chance Generation": {
        name: "Chance Generation",
        label: "Chance Creation",
        positionScope: ["D", "F"],
        maxValue: 1.0,
        decimalPlaces: 2,
        autoScale: false,
        componentMetrics: [
            "Slot LPRs p20oz",
            "Carries to Slot p20oz",
            "SCG Passes p20oz",
            "Shooting Chances Generated p20oz",
        ],
    },
    Scoring: {
        name: "Scoring",
        label: "Scoring",
        positionScope: ["D", "F"],
        maxValue: 1.0,
        decimalPlaces: 2,
        autoScale: false,
        componentMetrics: [
            "Shots On Net Off Rebound p20oz",
            "Shots From Slot On Net p20oz",
            "xGs p20oz",
            "Goals p20oz",
        ],
    },
    Goaltending: {
        name: "Goaltending",
        label: "Goaltending",
        positionScope: ["G"],
        maxValue: 1.0,
        decimalPlaces: 2,
        autoScale: false,
        componentMetrics: [
            "Actual to Expected Goals Against p60",
            "Inner Slot Save Percentage",
        ],
    },

    // Defense and Puck Battles Breakdown Metrics
    "Defensive Touches Rating": {
        name: "Defensive Touches Rating",
        label: "Defensive Kills",
        positionScope: ["D", "F"],
        maxValue: 1.0,
        decimalPlaces: 2,
        autoScale: false,
        componentMetrics: [
            "DZ Def Touches p20dzop",
            "NZ Def Touches p20nzop",
            "OZ Def Touches p20ozop",
        ],
    },
    "LPRs Rating": {
        name: "LPRs Rating",
        label: "LPRs & Puck Battles",
        positionScope: ["D", "F"],
        maxValue: 1.0,
        decimalPlaces: 2,
        autoScale: false,
        componentMetrics: [
            "DZ LPRs p20dznp",
            "NZ LPRs p20nznp",
            "OZ LPRs p20oznp",
        ],
    },
    "Distance Closed Percentage": {
        name: "Distance Closed Percentage",
        label: "% Distance Closed",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "Distance Closed Percentage in DZ",
            "Distance Closed Percentage in NZ",
            "Distance Closed Percentage in OZ",
        ],
    },
    "OGPs Ag WOI p20": {
        name: "OGPs Ag WOI p20",
        label: "OGPs Ag WOI p20",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: ["SCs OTR Ag WOI p20", "SCs OTC Ag WOI p20"],
    },

    // Defensive Touches Rating Breakdown Metrics
    "DZ Def Touches p20dzop": {
        name: "DZ Def Touches p20dzop",
        label: "DZ Def Kills p20dzop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "DZ Body Checks p20dzop",
            "DZ Stick Checks p20dzop",
            "DZ Blocked Passes p20dzop",
            "Blocked Shots p20dzop",
        ],
    },
    "NZ Def Touches p20nzop": {
        name: "NZ Def Touches p20nzop",
        label: "NZ Def Kills p20nzop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "NZ Body Checks p20nzop",
            "NZ Stick Checks p20nzop",
            "NZ Blocked Passes p20nzop",
        ],
    },
    "OZ Def Touches p20ozop": {
        name: "OZ Def Touches p20ozop",
        label: "OZ Def Kills p20ozop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "OZ Body Checks p20ozop",
            "OZ Stick Checks p20ozop",
            "OZ Blocked Passes p20ozop",
        ],
    },

    // DZ Def Touches p20dzop Breakdown Metrics
    "DZ Body Checks p20dzop": {
        name: "DZ Body Checks p20dzop",
        label: "DZ Body Checks p20dzop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "DZ Stick Checks p20dzop": {
        name: "DZ Stick Checks p20dzop",
        label: "DZ Stick Checks p20dzop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "DZ Blocked Passes p20dzop": {
        name: "DZ Blocked Passes p20dzop",
        label: "DZ Blocked Passes p20dzop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Blocked Shots p20dzop": {
        name: "Blocked Shots p20dzop",
        label: "Blocked Shots p20dzop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // NZ Def Touches p20 Breakdown Metrics
    "NZ Body Checks p20nzop": {
        name: "NZ Body Checks p20nzop",
        label: "NZ Body Checks p20nzop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "NZ Stick Checks p20nzop": {
        name: "NZ Stick Checks p20nzop",
        label: "NZ Stick Checks p20nzop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "NZ Blocked Passes p20nzop": {
        name: "NZ Blocked Passes p20nzop",
        label: "NZ Blocked Passes p20nzop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // OZ Def Touches p20 Breakdown Metrics
    "OZ Body Checks p20ozop": {
        name: "OZ Body Checks p20ozop",
        label: "OZ Body Checks p20ozop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "OZ Stick Checks p20ozop": {
        name: "OZ Stick Checks p20ozop",
        label: "OZ Stick Checks p20ozop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "OZ Blocked Passes p20ozop": {
        name: "OZ Blocked Passes p20ozop",
        label: "OZ Blocked Passes p20ozop",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // LPRs Rating Breakdown Metrics
    "DZ LPRs p20dznp": {
        name: "DZ LPRs p20dznp",
        label: "DZ LPRs p20dznp",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "Non-Contested DZ LPRs p20dznp",
            "Contested DZ LPRs p20dznp",
        ],
    },
    "NZ LPRs p20nznp": {
        name: "NZ LPRs p20nznp",
        label: "NZ LPRs p20nznp",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "Non-Contested NZ LPRs p20nznp",
            "Contested NZ LPRs p20nznp",
        ],
    },
    "OZ LPRs p20oznp": {
        name: "OZ LPRs p20oznp",
        label: "OZ LPRs p20oznp",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "Non-Contested OZ LPRs p20oznp",
            "Contested OZ LPRs p20oznp",
        ],
    },


    // Non-Contested LPRs Rating Breakdown Metrics
    "Non-Contested DZ LPRs p20dznp": {
        name: "Non-Contested DZ LPRs p20dznp",
        label: "DZ NPBs p20dznp",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Non-Contested NZ LPRs p20nznp": {
        name: "Non-Contested NZ LPRs p20nznp",
        label: "NZ NPBs p20nznp",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Non-Contested OZ LPRs p20oznp": {
        name: "Non-Contested OZ LPRs p20oznp",
        label: "OZ NPBs p20oznp",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // Contested LPRs Rating Breakdown Metrics
    "Contested DZ LPRs p20dznp": {
        name: "Contested DZ LPRs p20dznp",
        label: "DZ Puck Battles p20dznp",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Contested NZ LPRs p20nznp": {
        name: "Contested NZ LPRs p20nznp",
        label: "NZ Puck Battles p20nznp",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Contested OZ LPRs p20oznp": {
        name: "Contested OZ LPRs p20oznp",
        label: "OZ Puck Battles p20oznp",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // Distance Closed Percentage Breakdown Metrics
    "Distance Closed Percentage in DZ": {
        name: "Distance Closed Percentage in DZ",
        label: "% Distance Closed in DZ",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Distance Closed Percentage in NZ": {
        name: "Distance Closed Percentage in NZ",
        label: "% Distance Closed in NZ",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Distance Closed Percentage in OZ": {
        name: "Distance Closed Percentage in OZ",
        label: "% Distance Closed in OZ",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // OGPs Ag WOI p20 Breakdown Metrics
    "SCs OTR Ag WOI p20": {
        name: "SCs OTR Ag WOI p20",
        label: "SCs OTR Ag WOI p20",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "SCs OTC Ag WOI p20": {
        name: "SCs OTC Ag WOI p20",
        label: "SCs OTC Ag WOI p20",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // Touch Efficiency Breakdown Metrics
    "Adjusted PDPs p20dznz": {
        name: "Adjusted PDPs p20dznz",
        label: "PDPs p20dznz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "Line Carries p20dznz",
            "DZ PD Passes p20dznz",
            "NZ PD Passes p20dznz",
            "Defenders Beaten in DZ-NZ p20dznz",
        ],
    },
    "OZ Offensive Touches p20oz": {
        name: "OZ Offensive Touches p20oz",
        label: "OZ Offensive Touches p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "OZ Puck Protections p20oz",
            "OZ Passes p20oz",
            "Shots on Net p20oz",
        ],
    },
    "True Turnover Rate": {
        name: "True Turnover Rate",
        label: "True Turnover Rate",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "DZ True Turnover Rate",
            "NZ True Turnover Rate",
            "OZ True Turnover Rate",
        ],
    },

    // True Turnover Rate Breakdown Metrics
    "DZ True Turnover Rate": {
        name: "DZ True Turnover Rate",
        label: "DZ True Turnover Rate",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "NZ True Turnover Rate": {
        name: "NZ True Turnover Rate",
        label: "NZ True Turnover Rate",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "OZ True Turnover Rate": {
        name: "OZ True Turnover Rate",
        label: "OZ True Turnover Rate",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // Adjusted PDPs p20dznz Breakdown Metrics
    "Line Carries p20dznz": {
        name: "Line Carries p20dznz",
        label: "Line Carries p20dznz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "DZ Blue Line Carries p20dznz",
            "Red Line Carries p20dznz",
            "OZ Blue Line Carries p20dznz",
            "Off-the-Rush Passes p20oz",
        ],
    },
    "DZ PD Passes p20dznz": {
        name: "DZ PD Passes p20dznz",
        label: "DZ PD Passes p20dznz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "NZ PD Passes p20dznz": {
        name: "NZ PD Passes p20dznz",
        label: "NZ PD Passes p20dznz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [
            "NZ E/W Passes p20dznz",
            "NZ SE/SW Passes p20dznz",
        ],
    },
    "Defenders Beaten in DZ-NZ p20dznz": {
        name: "Defenders Beaten in DZ-NZ p20dznz",
        label: "Opp Beaten in DZ-NZ p20dznz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "NZ E/W Passes p20dznz": {
        name: "NZ E/W Passes p20dznz",
        label: "NZ E/W Passes p20dznz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "NZ SE/SW Passes p20dznz": {
        name: "NZ SE/SW Passes p20dznz",
        label: "NZ SE/SW Passes p20dznz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // Line Carries p20dznz Breakdown Metrics
    "DZ Blue Line Carries p20dznz": {
        name: "DZ Blue Line Carries p20dznz",
        label: "DZ Blue Line Carries p20dznz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Red Line Carries p20dznz": {
        name: "Red Line Carries p20dznz",
        label: "Red Line Carries p20dznz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "OZ Blue Line Carries p20dznz": {
        name: "OZ Blue Line Carries p20dznz",
        label: "OZ Blue Line Carries p20dznz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },


    // OZ Offensive Touches p20oz Breakdown Metrics
    "OZ Puck Protections p20oz": {
        name: "OZ Puck Protections p20oz",
        label: "OZ Puck Protections p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "OZ Passes p20oz": {
        name: "OZ Passes p20oz",
        label: "OZ Passes p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [
            "North OZ Passes p20oz",
            "South OZ Passes p20oz",
            "East-West OZ Passes p20oz",
            "Pass to the Slot p20oz",
        ],
    },
    "Shots on Net p20oz": {
        name: "Shots on Net p20oz",
        label: "Shots on Net p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // OZ Passes p20oz Breakdown Metrics
    "North OZ Passes p20oz": {
        name: "North OZ Passes p20oz",
        label: "North OZ Passes p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "South OZ Passes p20oz": {
        name: "South OZ Passes p20oz",
        label: "South OZ Passes p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "East-West OZ Passes p20oz": {
        name: "East-West OZ Passes p20oz",
        label: "East-West OZ Passes p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // Chance Generation Breakdown Metrics
    "Slot LPRs p20oz": {
        name: "Slot LPRs p20oz",
        label: "Slot LPRs p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Carries to Slot p20oz": {
        name: "Carries to Slot p20oz",
        label: "Carries to Slot p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "SCG Passes p20oz": {
        name: "SCG Passes p20oz",
        label: "Chance Creating Passes p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [
            "Defenders Beaten in OZ p20oz",
            "Passes for 1 Timer p20oz",
            "Pass to the Slot p20oz",
            "Shot Assists p20oz",
        ],
    },
    "Shooting Chances Generated p20oz": {
        name: "Shooting Chances Generated p20oz",
        label: "Shooting",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [
            "Outside Shots on Net w/ Goal or Rebound p20oz",
            "Shots From Slot On Net p20oz",
        ],
    },

    // Passing Chance Generation Breakdown Metrics
    "Defenders Beaten in OZ p20oz": {
        name: "Defenders Beaten in OZ p20oz",
        label: "Opp Beaten in OZ p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [
            "Defenders Beaten in OZ by Pass p20oz",
            "Defenders Beaten in OZ by Shot p20oz",
        ],
    },
    "Passes for 1 Timer p20oz": {
        name: "Passes for 1 Timer p20oz",
        label: "1 Timer Passes p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Off-the-Rush Passes p20oz": {
        name: "Off-the-Rush Passes p20oz",
        label: "Off-the-Rush Passes p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Pass to the Slot p20oz": {
        name: "Pass to the Slot p20oz",
        label: "Pass to the Slot p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Shot Assists p20oz": {
        name: "Shot Assists p20oz",
        label: "Shot Assists p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // Defenders Beat in OZ p20oz Breakdown Metrics
    "Defenders Beaten in OZ by Pass p20oz": {
        name: "Defenders Beaten in OZ by Pass p20oz",
        label: "Opp Beaten in OZ by Pass p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Defenders Beaten in OZ by Shot p20oz": {
        name: "Defenders Beaten in OZ by Shot p20oz",
        label: "Opp Beaten in OZ by Shot p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // Shooting Chance Generation Breakdown Metrics
    "Outside Shots on Net w/ Goal or Rebound p20oz": {
        name: "Outside Shots on Net w/ Goal or Rebound p20oz",
        label: "Outside Shots on Net w/ Goal or Rebound p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Shots From Slot On Net p20oz": {
        name: "Shots From Slot On Net p20oz",
        label: "Shots From Slot On Net p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // Scoring Breakdown Metrics
    // ("Goals p20" already included)
    "Shots On Net Off Rebound p20oz": {
        name: "Shots On Net Off Rebound p20oz",
        label: "Shots On Net Off Rebound p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "xGs p20oz": {
        name: "xGs p20oz",
        label: "xGs p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: ["Shots p20oz", "xG/Shot", "G/xG", "Goals p20oz"],
    },

    // xGs Breakdown Metrics
    "Shots p20oz": {
        name: "Shots p20oz",
        label: "Volume (Shots p20oz)",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "xG/Shot": {
        name: "xG/Shot",
        label: "Quality (xG/Shot)",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "G/xG": {
        name: "G/xG",
        label: "Execution (Goal/xG)",
        positionScope: ["D", "F"],
        maxValue: null,
        independentMaxValue: true,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },
    "Goals p20oz": {
        name: "Goals p20oz",
        label: "Goals p20oz",
        positionScope: ["D", "F"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // Goaltending Breakdown Metrics
    "Actual to Expected Goals Against p60": {
        name: "Actual to Expected Goals Against p60",
        label: "Act to Exp Goals Ag p60",
        positionScope: ["G"],
        maxValue: null,
        independentMaxValue: true,
        decimalPlaces: 2,
        autoScale: true,
        componentMetrics: [
            "ES Actual to Expected Goals Against p60",
            "PK Actual to Expected Goals Against p60",
        ],
    },
    "Inner Slot Save Percentage": {
        name: "Inner Slot Save Percentage",
        label: "Inner Slot Save %",
        positionScope: ["G"],
        maxValue: null,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: [
            "ES Inner Slot Save Percentage",
            "PK Inner Slot Save Percentage",
        ],
    },

    // Actual to Expected Goals Against Breakdown Metrics
    "ES Actual to Expected Goals Against p60": {
        name: "ES Actual to Expected Goals Against p60",
        label: "ES Act to Exp Goals Ag p60",
        positionScope: ["G"],
        maxValue: null,
        independentMaxValue: true,
        decimalPlaces: 2,
        autoScale: true,
        componentMetrics: [],
    },
    "PK Actual to Expected Goals Against p60": {
        name: "PK Actual to Expected Goals Against p60",
        label: "PK Act to Exp Goals Ag p60",
        positionScope: ["G"],
        maxValue: null,
        independentMaxValue: true,
        decimalPlaces: 2,
        autoScale: true,
        componentMetrics: [],
    },

    // Inner Slot Save Percentage Breakdown Metrics
    "ES Inner Slot Save Percentage": {
        name: "ES Inner Slot Save Percentage",
        label: "ES Inner Slot Save %",
        positionScope: ["G"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: [],
    },
    "PK Inner Slot Save Percentage": {
        name: "PK Inner Slot Save Percentage",
        label: "PK Inner Slot Save %",
        positionScope: ["G"],
        maxValue: null,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: [],
    },

    // Puck Handling Breakdown Metrics
    "Puck Handling": {
        name: "Puck Handling",
        label: "Puck Handling",
        positionScope: ["G"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [
            "ES LPRs p60",
            "ES DZ PD Passes p60",
            "ES True Turnover Rate",
            "ES Dump Outs p60",
        ],
    },

    "ES LPRs p60": {
        name: "ES LPRs p60",
        label: "ES LPRs p60",
        positionScope: ["G"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    "ES True Turnover Rate": {
        name: "ES True Turnover Rate",
        label: "ES True Turnover Rate",
        positionScope: ["G"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScaleMax: true,
        componentMetrics: [],
    },

    "ES Dump Outs p60": {
        name: "ES Dump Outs p60",
        label: "ES Dump Outs p60",
        positionScope: ["G"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // ES DZ PD Passes Breakdown Metrics
    "ES DZ PD Passes p60": {
        name: "ES DZ PD Passes p60",
        label: "ES DZ PD Passes p60",
        positionScope: ["G"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: ["ES Outlet Passes p60", "ES Stretch Passes p60"],
    },

    "ES Outlet Passes p60": {
        name: "ES Outlet Passes p60",
        label: "ES Outlet Passes p60",
        positionScope: ["G"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    "ES Stretch Passes p60": {
        name: "ES Stretch Passes p60",
        label: "ES Stretch Passes p60",
        positionScope: ["G"],
        maxValue: null,
        decimalPlaces: 2,
        autoScaleMax: true,
        componentMetrics: [],
    },

    // Quality Starts Percentage Breakdown Metrics
    "Quality Starts Percentage": {
        name: "Quality Starts Percentage",
        label: "Quality Games %",
        positionScope: ["G"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: [],
    },

    // No Rebound Rate Breakdown Metrics
    "No Rebound Rate": {
        name: "No Rebound Rate",
        label: "No Rebound %",
        positionScope: ["G"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: ["ES No Rebound Rate", "PK No Rebound Rate"],
    },
    "ES No Rebound Rate": {
        name: "ES No Rebound Rate",
        label: "ES No Rebound %",
        positionScope: ["G"],
        maxValue: null,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: [],
    },
    "PK No Rebound Rate": {
        name: "PK No Rebound Rate",
        label: "PK No Rebound %",
        positionScope: ["G"],
        maxValue: null,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: [],
    },

    // Experimental Metrics
    "Total Team Pressured Possession Share": {
        name: "Total Team Pressured Possession Share",
        label: "Team Poss % WOI",
        positionScope: ["F", "D"],
        maxValue: null,
        independentMaxValue: true,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: [
            "DZ Team Pressured Possession Share",
            "NZ Team Pressured Possession Share",
            "OZ Team Pressured Possession Share",
            "OZ Opp Pressured Possession Share",
        ],
    },
    "DZ Team Pressured Possession Share": {
        name: "DZ Team Pressured Possession Share",
        label: "DZ Team Poss % WOI",
        positionScope: ["F", "D"],
        maxValue: null,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: [],
    },
    "NZ Team Pressured Possession Share": {
        name: "NZ Team Pressured Possession Share",
        label: "NZ Team Poss % WOI",
        positionScope: ["F", "D"],
        maxValue: null,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: [],
    },
    "OZ Team Pressured Possession Share": {
        name: "OZ Team Pressured Possession Share",
        label: "OZ Team Poss % WOI",
        positionScope: ["F", "D"],
        maxValue: null,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: [],
    },
    "OZ Opp Pressured Possession Share": {
        name: "OZ Opp Pressured Possession Share",
        label: "Opp Under Press in OZ % WOI",
        positionScope: ["F", "D"],
        maxValue: null,
        isPercentage: true,
        decimalPlaces: 1,
        autoScale: true,
        componentMetrics: [],
    },
};
