import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppPage from "../layout/AppPage";
import OrgHeader from "../layout/OrgHeader";
import MainContent from "../layout/MainContent";
import useElementDimensions from "../hooks/use-element-dimensions";
import classes from "./PlayerPracticeSurvey.module.css";
import useAuth from "../hooks/use-auth";
import {
    fetchSurveys,
    fetchSurveyDetails,
    postSurveyResult,
} from "../store/surveys-actions";
import { fetchTeam } from "../store/teams-actions";
import { OptionRadioBar } from "../controls/OptionRadioBar";
import { uiActions } from "../store/ui-slice";

const PRACTICE_TYPES = {
    PRE: "Pre Practice",
    POST: "Post Practice",
};

const POSITIONS = [
    { id: "F", label: "Forward" },
    { id: "D", label: "Defense" },
    { id: "G", label: "Goalie" },
];

const RESET_BUTTON_DELAY = 10000; // 10 seconds
const AUTO_RESET_DELAY = 30000; // 30 seconds

const PlayerPracticeSurvey = () => {
    const dispatch = useDispatch();
    const { checkPermission } = useAuth();
    const canCreateSurveyResults = checkPermission(
        "core.can_create_survey_results"
    );

    const [selectedMode, setSelectedMode] = useState(null);
    const [currentSurvey, setCurrentSurvey] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const [answers, setAnswers] = useState({});
    const [playerPosition, setPlayerPosition] = useState(null);
    const [showThankYou, setShowThankYou] = useState(false);
    const [showResetButton, setShowResetButton] = useState(false);
    const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());

    const surveys = useSelector((state) => state.surveys.surveys);
    const surveyDetails = useSelector((state) => state.surveys.surveyDetails);
    const teams = useSelector((state) => state.teams.teams);
    const team = teams && teams[16];

    const { elementRef, dimensions } = useElementDimensions();

    // Load surveys on mount
    useEffect(() => {
        if (canCreateSurveyResults) {
            dispatch(fetchSurveys());
            dispatch(uiActions.showLoader());
        }
    }, [dispatch, canCreateSurveyResults]);

    // Load survey details when surveys are loaded
    useEffect(() => {
        if (surveys && Object.keys(surveys).length > 0) {
            const prePracticeSurvey = Object.values(surveys).find(
                (s) => s.name === PRACTICE_TYPES.PRE
            );
            const postPracticeSurvey = Object.values(surveys).find(
                (s) => s.name === PRACTICE_TYPES.POST
            );

            if (prePracticeSurvey) {
                dispatch(fetchSurveyDetails(prePracticeSurvey.id, "en"));
            }
            if (postPracticeSurvey) {
                dispatch(fetchSurveyDetails(postPracticeSurvey.id, "en"));
            }
        }
    }, [dispatch, surveys]);

    const resetSurvey = useCallback(() => {
        setPlayerPosition(null);
        setCurrentQuestionIndex(-1);
        setAnswers({});
        setShowResetButton(false);
        setLastInteractionTime(Date.now());
    }, []);

    // Handle inactivity
    useEffect(() => {
        if (currentQuestionIndex >= 0) {
            const resetButtonTimer = setTimeout(() => {
                setShowResetButton(true);
            }, RESET_BUTTON_DELAY);

            const autoResetTimer = setTimeout(() => {
                resetSurvey();
            }, AUTO_RESET_DELAY);

            return () => {
                clearTimeout(resetButtonTimer);
                clearTimeout(autoResetTimer);
            };
        }
    }, [currentQuestionIndex, lastInteractionTime, resetSurvey]);

    useEffect(() => {
        if (!teams[16]) {
            dispatch(fetchTeam(16));
            dispatch(uiActions.showLoader());
        }
    }, [teams, dispatch]);

    useEffect(() => {
        if (team && surveys && Object.keys(surveys).length > 0) {
            dispatch(uiActions.hideLoader());
        }
    }, [team, surveys, dispatch]);

    useEffect(() => {
        const handleBackButton = (e) => {
            if (selectedMode) {
                e.preventDefault();
                setSelectedMode(null);
                resetSurvey();
            }
        };

        window.addEventListener("popstate", handleBackButton);
        return () => window.removeEventListener("popstate", handleBackButton);
    }, [selectedMode, resetSurvey]);

    const handleModeSelection = (mode) => {
        setSelectedMode(mode);
        const survey = Object.values(surveys).find((s) => s.name === mode);
        setCurrentSurvey(survey);
        resetSurvey();
    };

    const handleAnswer = async (questionName, answer) => {
        setLastInteractionTime(Date.now());
        setShowResetButton(false);

        if (currentQuestionIndex === -1) {
            setPlayerPosition(answer);
            setCurrentQuestionIndex(0);
            return;
        }

        const newAnswers = { ...answers, [questionName]: answer };
        setAnswers(newAnswers);

        const surveyQuestions =
            surveyDetails[currentSurvey.id]?.en?.questions || [];

        if (currentQuestionIndex >= surveyQuestions.length - 1) {
            // Submit survey
            const resultData = {
                player_position: playerPosition,
                team_id: 16,
                competition_id: 1,
                language: "en",
                answers: newAnswers,
            };

            try {
                await dispatch(postSurveyResult(currentSurvey.id, resultData));
                setShowThankYou(true);
                setTimeout(() => {
                    setShowThankYou(false);
                    resetSurvey();
                }, 2000);
            } catch (error) {
                console.error("Failed to submit survey:", error);
            }
        } else {
            setCurrentQuestionIndex((prev) => prev + 1);
        }
    };

    if (!canCreateSurveyResults) {
        return (
            <AppPage>
                <div ref={elementRef}>
                    <OrgHeader
                        fullTitle="Practice Survey"
                        mediumTitle="Practice Survey"
                        shortTitle="Practice Survey"
                        bgColor={team?.primary_colour || "#AF1E2D"}
                        imgUrl={team?.alt_logo_url}
                        imgPosition="50% 50%"
                        parentWidth={dimensions.width}
                    />
                </div>
                <MainContent>
                    <div className={classes.no_access}>
                        You do not have access to create survey results.
                    </div>
                </MainContent>
            </AppPage>
        );
    }

    const renderQuestion = (question) => {
        if (!question) return null;

        let options;
        const config = question.config ? JSON.parse(question.config) : null;

        if (question.type === "multiple_choice" && config?.options) {
            options = config.options.map((opt) => ({
                id: opt.value,
                label: opt.value,
            }));
        } else if (question.type === "scale" && config?.min !== undefined) {
            options = Array.from(
                { length: config.max - config.min + 1 },
                (_, i) => ({
                    id: String(i + config.min),
                    label: String(i + config.min),
                })
            );
        }

        return (
            <div className={classes.question_container}>
                <div className={classes.question_text}>{question.text}</div>
                <div className={classes.options_container}>
                    {question.content?.min_label && (
                        <div
                            className={
                                classes.scale_label +
                                " " +
                                classes.scale_label_left
                            }
                        >
                            {question.content.min_label}
                        </div>
                    )}
                    {options && (
                        <OptionRadioBar
                            options={options}
                            selectedOption={null}
                            onSelect={(option) =>
                                handleAnswer(question.name, option.id)
                            }
                            OptionComponent={({ optionValue }) => (
                                <div className={classes.option_button}>
                                    {optionValue?.label}
                                </div>
                            )}
                        />
                    )}
                    {question.content?.max_label && (
                        <div
                            className={
                                classes.scale_label +
                                " " +
                                classes.scale_label_right
                            }
                        >
                            {question.content.max_label}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <AppPage>
            <div ref={elementRef}>
                <OrgHeader
                    fullTitle="Practice Survey"
                    mediumTitle="Practice Survey"
                    shortTitle="Practice Survey"
                    bgColor={team?.primary_colour || "#AF1E2D"}
                    imgUrl={team?.alt_logo_url}
                    imgPosition="50% 50%"
                    parentWidth={dimensions.width}
                />
            </div>
            <MainContent fullWidth={true}>
                {selectedMode && (
                    <div className={classes.back_button_container}>
                        <div
                            className={classes.back_button}
                            onClick={() => {
                                setSelectedMode(null);
                                setCurrentSurvey(null);
                                resetSurvey();
                            }}
                        >
                            <span className="material-symbols-outlined">
                                arrow_back
                            </span>
                        </div>
                    </div>
                )}
                <div className={classes.content_container}>
                    {showThankYou ? (
                        <div className={classes.thank_you}>Thank you!</div>
                    ) : !selectedMode ? (
                        <div className={classes.mode_selection}>
                            <button
                                className={classes.mode_button}
                                onClick={() =>
                                    handleModeSelection(PRACTICE_TYPES.PRE)
                                }
                            >
                                Pre Practice
                            </button>
                            <button
                                className={classes.mode_button}
                                onClick={() =>
                                    handleModeSelection(PRACTICE_TYPES.POST)
                                }
                            >
                                Post Practice
                            </button>
                        </div>
                    ) : currentQuestionIndex === -1 ? (
                        <div className={classes.question_container}>
                            <div className={classes.question_text}>
                                Position
                            </div>
                            <div className={classes.options_container}>
                                <OptionRadioBar
                                    options={POSITIONS}
                                    selectedOption={null}
                                    onSelect={(option) =>
                                        handleAnswer(null, option.id)
                                    }
                                    OptionComponent={({ optionValue }) => (
                                        <div className={classes.option_button}>
                                            {optionValue?.label}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    ) : (
                        renderQuestion(
                            surveyDetails[currentSurvey.id]?.en?.questions[
                                currentQuestionIndex
                            ]
                        )
                    )}

                    {showResetButton && (
                        <button
                            className={classes.reset_button}
                            onClick={resetSurvey}
                        >
                            Reset
                        </button>
                    )}
                </div>
            </MainContent>
        </AppPage>
    );
};

export default PlayerPracticeSurvey;
